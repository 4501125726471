.moodboard-container {
    width: 100vw;
    max-width: 100%!important;
}
.moodboard-container>.row {
    position: relative;
}
.row>.moodboard-bg{
    /* position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    
    width: 100%;
    min-height: 130px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    /* z-index: -1; */
}

/* .moodboard div {
    background-color: transparent;
} */

.moodboard-text {
    /* position: absolute;
    top: 50%;  */
    /* transform: translate(0, -50%); */
    font-size: 2.2em;
    font-family: Open Sans;
font-style: normal;
font-weight: bold;
/* width: 100%; */
    /* font-family: "Georgia"; */
}   

.row.moodboard-header-row {
    position: absolute;
    height: 100%;
    width: 100%;
    align-items: center;
    padding-left:15%;
}

.btn-outline-secondary.moodboard-exercies-button {
    background-color: white;
    box-sizing: border-box;
    font-size: 1rem;
    /* padding-top : 10px; */
    padding-left : 20px;
    padding-right : 20px;
    border: none;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
/* border-radius: 10px; */
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.moodboard-guide-1 {
    text-align: center;
    justify-content: center;
}
.moodboard-guide-1>div {
    border: 3px solid #98AECA;
    margin:20px;
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
padding: 10px;
width: auto;
font-size: 1.1em;
border-radius: 10px;
padding-left: 30px;
padding-right: 30px;
min-width: 70%;
}

.mood-borad-emoji-col {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: transform .2s; 
    margin-bottom: 40px;
    font-size: 1.2rem;
    font-family: Open Sans;
font-style: normal;
font-weight: bold;
color: rgba(0, 0, 0, 0.6);


}


.mood-borad-emoji-col:hover {
    transform: scale(1.1);
}

.mood-borad-emoji {
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
    /* padding: 30px; */
    /* border: 1px black solid; */
    border-radius: 9999px;
    padding: 30px;
    width: max-content;
    margin-bottom: 20px;

}
.moodboard-emoji-area {
    text-align: center;
    justify-content: center;
    padding-left: 10%;
    padding-top: 30px;
    padding-right: 10%;

}

.moodboard-disclaimer {
    padding-left: 10%;
    padding-right: 10%;

}
.moodboard-disclaimer h3{
    margin-top: 40px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}